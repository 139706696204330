<template>
  <v-container class="black">
    <v-row>
      <v-col
        cols="12"
      >
        <press />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    components: {
      Press: () => import('@/components/Press.vue')
    },
    data () {
      return {
        items: [
          {
            src: '/static/incendiary-live-2023.jpg'
          },
          {
            src: '/static/t-birds-live-2023.jpg'
          },
          {
            src: '/static/fair-live-2023.jpg'
          },
          {
            src: '/static/band-ramkat.jpg'
          }
        ]
      }
    }
  }
</script>
